* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    box-sizing: border-box;
}

code {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

html {
    font-size: 62.5%; //10px (62.25%) when the default font size is 16px (100%)
}

$white: white;
$qi-blue: #0b1f82;
$light-gray-level1: #f0f2f6;
$light-gray-level2: #e0e2ee;
$light-gray-level3: #cccccc;
$dark-gray-level1: #525252;
$dark-gray-level2: #2d2d2d;
$light-green: #c5efe1;
$light-red: #e8b9c8;
$light-yellow: #fcd999;
$light-dark-yellow: #f5d140;
$light-blue: #9ca5ce;
$error-red: #b82c2c;

.background-light-green {
    background-color: $light-green;
}
.background-light-red {
    background-color: $light-red;
}
.background-light-yellow {
    background-color: $light-yellow;
}
.background-light-blue {
    background-color: $light-blue;
}
.background-light-gray {
    background-color: $light-gray-level3;
}
.background-dark-yellow {
    background-color: $light-dark-yellow;
}

::-webkit-scrollbar {
width: 8px;
}

::-webkit-scrollbar-track {
background: #f1f1f1;
border-radius: 8px;
}

::-webkit-scrollbar-thumb {
background: #888;
border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
background: #555;
}

.page-container {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    header {
        margin: 1.4rem auto;
        
        h2 {
            text-align: center;
            #not-bold {
                font-weight: normal;
            }
        }
    }
}

.filter-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem;
    border-bottom: .1rem solid $light-gray-level2;
    background-color: $light-gray-level1;
    gap: 2rem;
    width: 100%;
    min-height: 6.8rem;
    justify-content: space-between;
}

.filter {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: .2rem;

    label {
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1.3rem;
    }

    input, select {
        font-size: 1.1rem;
        line-height: 1.3rem;
        outline: none;
        border: .1rem solid $light-gray-level3;
        border-radius: .4rem;
        min-height: 2.8rem;
        background-color: white;

        &:focus {
            border-color: $dark-gray-level1;
        }
    }
    
    input {
        padding: 1rem .5rem;
    }

    select {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.filter__btn {
    margin: auto auto auto 0;
    min-width: 10rem;
}

.btn {
    border: .1rem solid $light-gray-level3;
    border-radius: 5rem; 
    padding: .8rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.btn--animated {
    border: .1rem solid $light-gray-level3;
    box-shadow: .1rem .2rem .2rem .1rem $light-gray-level3;
    
    &:hover:not([disabled]) {
        cursor: pointer;
        border: .1rem solid $light-gray-level3;
        box-shadow: .1rem .4rem .4rem .1rem $light-gray-level3;
        transform: translateY(-.1rem);
    }
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn--white {
    background-color: $white;
    color: $dark-gray-level2;
}
.btn--green {
    background-color: $light-green;
    color: $dark-gray-level2;
}
.btn--red {
    background-color: $light-red;
    color: $dark-gray-level2;
}

.btn__content--gap {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.btn__icon {
    height: 1.8rem;
    width: 1.8rem;
}

.btn--no-border{
    border: none;
}

.message__text{
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: $dark-gray-level1;
    text-align: center;
}

.message__container--centered {
    margin: auto;
}

.message__container {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.inbox {
    width: calc(100% - 2rem);
    margin: .5rem 1rem;
    font-size: 1.5rem;
}

.inbox__header {
    width: calc(100% - 2rem);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 1rem;
    font-weight: bold;
}

.inbox__body {
    width: calc(100% - 2rem);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 1rem;
    border: .1rem solid $light-gray-level2;
    padding: 1rem;
    border-radius: .8rem;
    margin: .2rem;
    width: 100%;
    
    p {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7rem;
    }
}

.inbox__cell--center {
    text-align: center;
    margin: auto;
}

.inbox__cell--right {
    margin: auto 0 auto auto;
}

.inbox__cell--left {
    margin: auto auto auto 0;
}

.inbox__cell--row-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.inbox__cell--column-container {
    display: flex;
    flex-direction: column;
}

.inbox__cell--gap-2 {
    gap: 2rem;
}

.avatar-container {
    display: flex;
    justify-content: center;
}

.avatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    color: #525252;
    background-color: #f0f2f6;
    border: .1rem solid #bfbfbfab;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datetime-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f0f2f6;
    border: .1rem solid $light-gray-level3;
    padding: .8rem;
    border-radius: .8rem;

    #day {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 2rem;
    }

    #time {
        letter-spacing: .02rem;
        min-width: 6.4rem;
    }
}

.data-field {
    margin: .5rem auto .5rem 0;
    gap: .2rem;
}

.data-field__label {
    word-break: break-all;
}

.data-field__value {
    color: $dark-gray-level1;
}

.inbox__cell--normal-text {
    color: $dark-gray-level1;
}

.status-icon-text {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: .4rem;
    padding: .2rem;
    gap: .5rem;
    white-space:  nowrap;
    
    p {
        color: $dark-gray-level2;
        text-align: center;
        width: 16rem;
    }
}

.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto .5rem .5rem .5rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    gap: 1rem;

    button {
        background-color: $white;
        padding: .6rem 1rem;
        margin: 0 0.5rem;
        border-radius: 0.25rem;
        border: .1rem solid $light-gray-level2;
        color: #333;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &:hover:not([disabled]) {
            background-color: #e0e5f0;
        }

        
        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    #selected {
        transform: translateY(.1rem);
        font-weight: bold;
        background-color: $light-gray-level2;
    }
}

.btn__container--center {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.envelope__signer-cell-btn--width {
    min-width: 3.6rem;
}

.envelope__signer-cell--width {
    min-width: 26rem;
}

.envelope__date-time--height {
    height: 7.4rem;
}

.settings__logo {
    margin: .5rem auto;
    width: 60%;
    border-radius: .8rem;
    padding: 1rem;
    border: .1rem solid $light-gray-level2;
}

.color-field {
    margin: .5rem auto .5rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
        background-color: transparent;
        border: none;
        width: 4rem;
        height: 4rem;
        padding: 0;
        margin: 0;
    }
}

.color-field--gray {
    color: $dark-gray-level1;
}

.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $qi-blue;
    border-bottom: .1rem solid $light-gray-level2;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.4rem;

    img {
        height: 5rem;
        margin: 0 auto 0 0;
    }
}

.top-bar__text {
    margin: 1.5rem;
    color: $white;
}

.side-bar {
    border-right: .1rem solid $light-gray-level2;
    border-bottom: .1rem solid $light-gray-level2;
    display: flex;
    flex-direction: column;
    width: 18.5rem;
    height: 100%;
}

.side-bar__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    min-height: 6.8rem;
    margin: 0;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.side-bar__item-container--to-end {
    display: flex;
    flex-direction: column;
    margin: auto 0 0 0;
}

.side-bar__item--selected {
    color: $qi-blue;
    border-right: .4rem solid $qi-blue;
    font-weight: bold;
    background-color: $white;
    background-color: $light-gray-level1;
}

.side-bar__item--normal {
    color: $dark-gray-level1;
}

.label-input {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: .2rem;
    
    label {
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    
    #error {
        border: .1rem solid $error-red;
    }

    input {
        padding: 1rem .5rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
        border: .1rem solid $light-gray-level3;
        border-radius: .4rem;

        &:focus {
            outline: none;
            border: .1rem solid $dark-gray-level1;
        }
    }
    
    input::placeholder {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
}

.error-message {
    color: $error-red;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.signer-data-dialog__error {
    height: 2.6rem;
}

.signer-data-dialog__btn {
    margin: 1.6rem auto 0 auto;
    min-width: 10rem;
    min-height: 3.2rem;
}

dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    border: .1rem solid #cccccc !important;
    box-shadow: .1rem .2rem .2rem .1rem #cccccc;
    border-radius: .8rem;
    padding: .5rem 1rem;
}

.dialog--temporary {
    background-color: $light-gray-level1;
    color: $dark-gray-level1;
}

.dialog__row-container {
    display: flex;
    flex-direction: row;
    padding: .5rem;
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
}

.dialog__column-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: .5rem;

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.9rem;
    }
}

.flex-row {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.gap--5 {
    gap: .5rem;
}

.dialogTitle {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    color: #0b1f82;
    font-size: 14px;
    line-height: 20px;
}